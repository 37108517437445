<template>
  <component :is="promotionData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="promotionData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب بيانات العروض الترويجية
      </h4>
      <div class="alert-body">
        لم يتم العثور على العروض الترويجية مع معرف العروض الترويجية هذا.راجع
        <b-link
          class="alert-link"
          :to="{ name: 'promotions-list'}"
        >
          قائمة العروض الترويجية
        </b-link>
        العروض الترويجية الآخرين.
      </div>
    </b-alert>

    <b-tabs
      v-if="promotionData"
      pills
    >
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('promotion_edit') }}</span>
        </template>
        <promotion-edit-tab-information
          :promotion-data="promotionData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import promotionStore from '../promotionStore';
import PromotionEditTabInformation from './PromotionEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    PromotionEditTabInformation,
  },
  setup() {
    const promotionData = ref(null);

    const PROMOTION_APP_STORE_MODULE_NAME = 'app-promotion';

    // Register module
    if (!store.hasModule(PROMOTION_APP_STORE_MODULE_NAME)) store.registerModule(PROMOTION_APP_STORE_MODULE_NAME, promotionStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_APP_STORE_MODULE_NAME)) store.unregisterModule(PROMOTION_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-promotion/fetchPromotion', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data: promotion } = response.data;
        promotionData.value = promotion;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          promotionData.value = undefined;
        }
      });

    return {
      promotionData,
    };
  },
};
</script>

<style>

</style>
